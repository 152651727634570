import { Routes } from '@angular/router';
import { AuthGuard } from 'shared/app/guards';

export const ROUTES: Routes = [
  {
    canActivate: [AuthGuard],
    path: '',
    loadChildren: () => import('./chatbot/chatbot.module').then(m => m.ChatbotModule)
  }
];
