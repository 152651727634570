import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

import { NotificationService } from 'shared/app/components/notification/notification.service';

import {
  SendUserMessage,
  StoreChatId,
  StoreChatBotMessage,
  SetUserInputDisabled,
  SetShowJumpingDots,
  SetChatLanguage,
  SetChatTopic,
  StoreChatBotReferences,
  UpdateChatBotMessage,
  SetAvailableLanguages,
  SetAvailableContext,
  ChangeChatSession,
  GetChatLog,
  StoreFeedbackItem,
  DeleteConversation,
  EditConversation,
  SetSwitchingChatAvailable,
  SetSelectedContext,
  StoreFeedbackReference,
  UpdateReferencesContext,
  SetSelectedModel,
  IsMobileVersion
} from './chat.action';
import { ChatbotMessage } from '../../chatbot/chatbot-message';
import { ChatbotService } from '../../chatbot/chatbot.service';
import {
  ChatLogSession,
  ChatSession,
  MessageReferencedSpans,
  MessageReference
} from '../../chatbot/chatbot-response';
import { FeedbackService } from '../../chatbot/feedback/feedback.service';
import { Subscription } from 'rxjs';

export interface ChatModel {
  chatBotMessages: ChatbotMessage[];
  chatId: string;
  userInputDisabled: boolean;
  showJumpingDots: boolean;
  chatLanguage: string;
  chatTopic: string;
  messageReferences: MessageReference[];
  availableLanguages: { language: string; viewLanguage: string }[];
  availableContexts: string[];
  chatLog: ChatLogSession[];
  selectedContext: string;
  selectedModel: string;
  IsMobileVersion: boolean;
}

export const DEFAULT_STATE: ChatModel = {
  chatBotMessages: [],
  chatId: null,
  userInputDisabled: false,
  showJumpingDots: false,
  chatLanguage: 'de-CH',
  messageReferences: [],
  chatTopic: null,
  availableLanguages: [],
  availableContexts: [],
  chatLog: [],
  selectedContext: null,
  selectedModel: null,
  IsMobileVersion: false
};

@UntilDestroy()
@State<ChatModel>({
  name: 'chat',
  defaults: DEFAULT_STATE
})
@Injectable()
export class ChatState {
  httpRequest: Subscription[] = [];

  constructor(
    private chatbotService: ChatbotService,
    private translationService: TranslateService,
    private feedbackService: FeedbackService,
    private notifcationService: NotificationService
  ) {}

  @Action(SendUserMessage)
  public sendUserMessage(context: StateContext<ChatModel>, action: SendUserMessage): void {
    const { messageWrapper, invokedBySendMessage } = action.payload;
    const state = context.getState();
    const { chatLanguage, chatTopic, selectedContext, selectedModel, chatId, chatBotMessages } =
      state;
    context.dispatch(new SetUserInputDisabled(true));
    context.dispatch(new SetShowJumpingDots(true));
    context.dispatch(new SetSwitchingChatAvailable(false));
    if (chatId === null) {
      context.patchState({
        chatBotMessages: [...chatBotMessages, messageWrapper]
      });
      const request = this.chatbotService
        .startConversation(chatLanguage, selectedContext, selectedModel, chatTopic)
        .pipe(untilDestroyed(this))
        .subscribe(chatbotResponse => {
          if (chatbotResponse.chatId != null) {
            context.dispatch(new StoreChatId(chatbotResponse.chatId));
            context.dispatch(
              new SendUserMessage({
                messageWrapper: messageWrapper,
                invokedBySendMessage: true
              })
            );
          } else {
            context.dispatch(new SetShowJumpingDots(false));
            this.notifcationService.openNotification(chatbotResponse.message, true);
          }
        });
      this.httpRequest.push(request);
    } else {
      if (!invokedBySendMessage) {
        context.patchState({
          chatBotMessages: [...chatBotMessages, messageWrapper]
        });
      }
      const mode = chatTopic;
      if (mode === 'Cross-Border Check') {
        const request = this.chatbotService
          .sendRulesMessage(messageWrapper.message, chatId)
          .pipe(untilDestroyed(this))
          .subscribe(chatbotResponse => {
            context.dispatch(new StoreChatBotMessage(chatbotResponse));
            context.dispatch(new SetUserInputDisabled(false));
            context.dispatch(new GetChatLog());
            context.dispatch(new SetSwitchingChatAvailable(true));
          });
        this.httpRequest.push(request);
      } else if (mode === 'Cross-Border Policies') {
        const request = this.chatbotService
          .sendCountryManualsMessage(messageWrapper.message, chatId)
          .pipe(untilDestroyed(this))
          .subscribe(chatbotResponse => {
            context.dispatch(new StoreChatBotMessage(chatbotResponse));
            context.dispatch(new SetUserInputDisabled(false));
            context.dispatch(new GetChatLog());
            context.dispatch(new SetSwitchingChatAvailable(true));
          });
        this.httpRequest.push(request);
      } else {
        const request = this.chatbotService
          .sendMessage(messageWrapper.message, chatId)
          .pipe(untilDestroyed(this))
          .subscribe(chatbotResponse => {
            context.dispatch(new StoreChatBotMessage(chatbotResponse));
            context.dispatch(new SetUserInputDisabled(false));
            context.dispatch(new GetChatLog());
            context.dispatch(new SetSwitchingChatAvailable(true));
          });
        this.httpRequest.push(request);
      }
    }
  }

  @Selector()
  public static getChatId(state: ChatModel): string {
    return state.chatId;
  }

  @Action(StoreChatId)
  public storeChatId(context: StateContext<ChatModel>, action: StoreChatId): void {
    context.patchState({
      chatId: action.payload
    });
  }

  @Selector()
  public static getChatBotMessages(state: ChatModel): ChatbotMessage[] {
    return state.chatBotMessages;
  }

  @Action(StoreChatBotMessage)
  public storeChatBotMessages(context: StateContext<ChatModel>, action: StoreChatBotMessage): void {
    const state = context.getState();
    context.patchState({
      chatBotMessages: [
        ...state.chatBotMessages,
        {
          messageId: action.payload.messageId,
          message: action.payload.message.replace(/\n/g, '<br>'),
          type: action.payload.type,
          source: 'bot',
          evaluatedRulesets: action.payload.evaluatedRulesets,
          evaluationInformation: action.payload.evaluationInformation,
          referencedSpans: action.payload.referencedSpans,
          availableOptions: action.payload.availableOptions,
          result: action.payload.result,
          terms: action.payload.terms,
          feedback: action.payload.feedback
        }
      ]
    });
    context.dispatch(new SetShowJumpingDots(false));
  }

  @Action(UpdateChatBotMessage)
  public updateChatBotMessages(
    context: StateContext<ChatModel>,
    action: UpdateChatBotMessage
  ): void {
    const state = context.getState();
    const chatBotMessages = state.chatBotMessages;
    const index = chatBotMessages.findIndex(
      message => message.messageId === action.payload.messageId
    );
    if (index !== -1) {
      chatBotMessages[index] = {
        messageId: action.payload.messageId,
        message: action.payload.message.replace(/\n/g, '<br>'),
        type: action.payload.type,
        source: 'bot',
        referencedSpans: action.payload.referencedSpans,
        availableOptions: action.payload.availableOptions,
        result: action.payload.result,
        terms: action.payload.terms,
        feedback: action.payload.feedback
      };
    } else {
      chatBotMessages.push({
        messageId: action.payload.messageId,
        message: action.payload.message.replace(/\n/g, '<br>'),
        type: action.payload.type,
        source: 'bot',
        referencedSpans: action.payload.referencedSpans,
        availableOptions: action.payload.availableOptions,
        result: action.payload.result,
        terms: action.payload.terms
      });
    }
    context.patchState({
      chatBotMessages: chatBotMessages
    });
  }

  @Selector()
  public static getUserInputDisabled(state: ChatModel): boolean {
    return state.userInputDisabled;
  }

  @Action(SetUserInputDisabled)
  public setUserInputDisabled(
    context: StateContext<ChatModel>,
    action: SetUserInputDisabled
  ): void {
    context.patchState({
      userInputDisabled: action.payload
    });
  }

  @Selector()
  public static getShowJumpingDots(state: ChatModel): boolean {
    return state.showJumpingDots;
  }

  @Action(SetShowJumpingDots)
  public setShowJumpingDots(context: StateContext<ChatModel>, action: SetShowJumpingDots): void {
    context.patchState({
      showJumpingDots: action.payload
    });
  }

  @Selector()
  public static getChatLanguage(state: ChatModel): string {
    return state.chatLanguage;
  }

  @Action(SetChatLanguage)
  public setChatLanguage(context: StateContext<ChatModel>, action: SetChatLanguage): void {
    context.patchState({
      chatLanguage: action.payload
    });
  }

  @Selector()
  public static getChatTopic(state: ChatModel): string {
    return state.chatTopic;
  }

  @Action(SetChatTopic)
  public setChatTopic(context: StateContext<ChatModel>, action: SetChatTopic): void {
    context.patchState({ chatTopic: action.payload });
  }

  @Selector()
  public static getMessageReferences(state: ChatModel): MessageReference[] {
    return state.messageReferences;
  }

  @Action(StoreChatBotReferences)
  public storeChatBotReferences(
    context: StateContext<ChatModel>,
    action: StoreChatBotReferences
  ): void {
    const state = context.getState();
    context.patchState({
      messageReferences: [
        ...state.messageReferences,
        {
          referenceId: action.payload.referenceId,
          referencedSpans: action.payload.referencedSpans,
          lastSentence: action.payload.lastSentence
        }
      ]
    });
  }

  @Action(UpdateReferencesContext)
  public updateReferencesContext(
    context: StateContext<ChatModel>,
    action: UpdateReferencesContext
  ): void {
    const state = context.getState();
    const messageReferences = state.messageReferences;
    const index = messageReferences.findIndex(
      reference => reference.referenceId === action.payload.referenceId
    );
    if (index !== -1) {
      messageReferences[index].referencedSpans = action.payload.context;
    }
    context.patchState({
      messageReferences: messageReferences
    });
  }

  @Selector()
  public static getAvailableLanguages(
    state: ChatModel
  ): { language: string; viewLanguage: string }[] {
    return state.availableLanguages;
  }

  @Action(SetAvailableLanguages)
  public setAvailableLanguages(
    context: StateContext<ChatModel>,
    action: SetAvailableLanguages
  ): void {
    context.patchState({
      availableLanguages: action.payload
    });
  }

  @Selector()
  public static getAvailableContexts(state: ChatModel): string[] {
    return state.availableContexts;
  }

  @Selector()
  public static getSelectedContext(state: ChatModel): string {
    return state.selectedContext;
  }

  @Action(SetSelectedContext)
  public setSelectedContext(context: StateContext<ChatModel>, action: SetSelectedContext): void {
    context.patchState({
      selectedContext: action.payload
    });
  }

  @Selector()
  public static getSelectedModel(state: ChatModel): string {
    return state.selectedModel;
  }

  @Action(SetSelectedModel)
  public setSelectedModel(context: StateContext<ChatModel>, action: SetSelectedModel): void {
    context.patchState({
      selectedModel: action.payload
    });
  }

  @Action(SetAvailableContext)
  public setAvailableContext(context: StateContext<ChatModel>, action: SetAvailableContext): void {
    context.patchState({
      availableContexts: action.payload
    });
  }

  @Selector()
  public static getChatLog(state: ChatModel): ChatLogSession[] {
    return state.chatLog;
  }

  @Action(GetChatLog)
  public getChatLog(context: StateContext<ChatModel>): void {
    const request = this.chatbotService
      .getChatLog()
      .pipe(untilDestroyed(this))
      .subscribe(chatLog => {
        if (chatLog.length > 0) {
          if (chatLog[0].id === '') {
            this.notifcationService.openNotification(chatLog[0].title, true);
            context.patchState({
              chatLog: chatLog
            });
          }
        }
        context.patchState({
          chatLog: chatLog
        });
      });

    this.httpRequest.push(request);
  }

  @Action(ChangeChatSession)
  public async changeChat(
    context: StateContext<ChatModel>,
    action: ChangeChatSession
  ): Promise<void> {
    context.dispatch(new SetSwitchingChatAvailable(false));
    context.dispatch(new SetUserInputDisabled(true));
    this.httpRequest.forEach(request => request?.unsubscribe());
    this.httpRequest = [];
    if (action.payload.chatId == null) {
      const userSetLanguage = localStorage.getItem('language');
      const availableLanguages = context.getState().availableLanguages;
      if (userSetLanguage == null || availableLanguages.length === 1) {
        //When no language is set, set language to first available language
        context.dispatch(new SetChatLanguage(availableLanguages[0].language));
      } else {
        //When lang was set by user
        if (userSetLanguage == 'en') {
          context.dispatch(new SetChatLanguage(userSetLanguage + '-US'));
        } else {
          context.dispatch(new SetChatLanguage(userSetLanguage + '-CH'));
        }
      }
      context.dispatch(new SetChatTopic(null));
      context.dispatch(new StoreChatId(null));
      context.patchState({
        chatBotMessages: [],
        messageReferences: []
      });
      context.dispatch(new SetUserInputDisabled(false));
      context.dispatch(new SetSwitchingChatAvailable(true));
    } else {
      await new Promise<void>((resolve, reject) => {
        this.chatbotService
          .changeChat(action.payload.chatId)
          .pipe(untilDestroyed(this))
          .subscribe((chatSession: ChatSession) => {
            context.dispatch(new SetChatLanguage(chatSession.language));
            context.dispatch(new SetChatTopic(chatSession.topic));
            context.dispatch(new StoreChatId(chatSession.id));
            context.patchState({
              chatBotMessages: [],
              messageReferences: []
            });
            this.addMessageToChatbotMessages(chatSession, context, chatSession.topic != null);
            context.dispatch(new SetUserInputDisabled(false));
            context.dispatch(new SetSwitchingChatAvailable(true));
            resolve();
          }, reject);
      });
    }
  }

  private addMessageToChatbotMessages(
    chatSession: ChatSession,
    referencedSpans: StateContext<ChatModel>,
    removeTopicResponse: boolean
  ) {
    //Remove first entry if topic set since topic is static
    if (removeTopicResponse) {
      chatSession.chat_history.shift();
    }
    chatSession.chat_history.forEach(chatMessage => {
      if (chatMessage.role === 'assistant') {
        referencedSpans.dispatch(
          new StoreChatBotMessage({
            message: chatMessage.content,
            type: chatMessage.type,
            messageId: chatMessage.id,
            chatId: chatSession.id,
            referencedSpans: chatMessage.referencedSpans,
            evaluatedRulesets: chatMessage.evaluatedRulesets,
            evaluationInformation: chatMessage.evaluationInformation,
            availableOptions: [],
            result: chatMessage.result,
            terms: chatMessage.terms,
            feedback: chatMessage.feedback
          })
        );
      } else {
        referencedSpans.patchState({
          chatBotMessages: [
            ...referencedSpans.getState().chatBotMessages,
            {
              message: chatMessage.content,
              type: chatMessage.type,
              messageId: chatMessage.id,
              source: 'user'
            }
          ]
        });
      }
    });
  }

  @Action(StoreFeedbackItem)
  public storeFeedbackItem(
    referencedSpans: StateContext<ChatModel>,
    action: StoreFeedbackItem
  ): void {
    const state = referencedSpans.getState();
    const chatBotMessages = state.chatBotMessages;
    const index = chatBotMessages.findIndex(
      message => message.messageId === action.payload.messageId
    );
    if (index !== -1) {
      chatBotMessages[index].feedback = action.payload.feedback;
      this.feedbackService
        .sendFeedback(state.chatId, action.payload.messageId, action.payload.feedback)
        .subscribe();
    }
    referencedSpans.patchState({
      chatBotMessages: chatBotMessages
    });
  }

  @Action(DeleteConversation)
  public deleteConversation(
    referencedSpans: StateContext<ChatModel>,
    action: DeleteConversation
  ): void {
    const deletedChatId = action.payload;
    this.chatbotService
      .deleteChat(deletedChatId)
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        if (response.deleted === true) {
          this.notifcationService.openNotification(
            this.translationService.instant('DELETE_CONVERSATION.SUCCESS_MESSAGE'),
            false
          );
          referencedSpans.dispatch(new ChangeChatSession({ chatId: null }));
          referencedSpans.patchState({
            ...referencedSpans,
            chatLog: referencedSpans.getState().chatLog.filter(chat => chat.id !== deletedChatId)
          });
        } else {
          this.notifcationService.openNotification(response.error, true);
          console.log('ERROR');
        }
      });
  }

  @Action(EditConversation)
  public editConversation(
    referencedSpans: StateContext<ChatModel>,
    action: EditConversation
  ): void {
    this.chatbotService
      .editChat(action.payload.id, action.payload.name)
      .pipe(untilDestroyed(this))
      .subscribe(chatSession => {
        if (chatSession.id === '' && chatSession.title !== '') {
          this.notifcationService.openNotification(chatSession.title, true);
        } else {
          this.notifcationService.openNotification(
            this.translationService.instant('EDIT_CONVERSATION.SUCCESS_MESSAGE'),
            false
          );
          referencedSpans.dispatch(new GetChatLog());
        }
      });
  }

  @Action(StoreFeedbackReference)
  public storeFeedbackReference(
    referencedSpans: StateContext<ChatModel>,
    action: StoreFeedbackReference
  ): void {
    const { chatId } = referencedSpans.getState();
    // eslint-disable-next-line prefer-const
    let { messageId, documentid, feedback } = action.payload;
    messageId = action.payload.messageId.slice(0, -2);

    this.feedbackService
      .sendFeedbackReference(chatId, messageId, documentid, feedback)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const { messageReferences } = referencedSpans.getState();
        const referenceID = messageReferences.findIndex(
          (reference: MessageReference) => reference.referenceId === action.payload.messageId
        );

        const referencedSpansIndex = messageReferences[referenceID].referencedSpans.findIndex(
          (referencedSpans: MessageReferencedSpans) => {
            return (
              referencedSpans.sections.findIndex(section => section.documentId === documentid) !==
              -1
            );
          }
        );
        const sectionIndex = messageReferences[referenceID].referencedSpans[
          referencedSpansIndex
        ].sections.findIndex(section => section.documentId === documentid);

        messageReferences[referenceID].referencedSpans[referencedSpansIndex].sections[
          sectionIndex
        ].feedback = feedback;

        referencedSpans.patchState({
          messageReferences: messageReferences
        });
      });
  }

  @Action(IsMobileVersion)
  public isMobileVersion(context: StateContext<ChatModel>, action: IsMobileVersion): void {
    context.patchState({
      IsMobileVersion: action.payload
    });
  }

  @Selector()
  public static getIsMobileVersion(state: ChatModel): boolean {
    return state.IsMobileVersion;
  }
}
