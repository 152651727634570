import { Component, Inject } from '@angular/core';

import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

interface NotificationData {
  message: string;
  error: boolean;
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  status: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: NotificationData,
    private _snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    this.status = this.data.error
      ? this.translate.instant('NOTIFICATION.ERROR')
      : this.translate.instant('NOTIFICATION.SUCCESS');
  }

  close() {
    this._snackBar.dismiss();
  }
}
