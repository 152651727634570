<ng-template [ngIf]="currentUser">
  <apx-navigation-bar
    *ngIf="navigationBarConfig"
    [user]="currentUser"
    [config]="navigationBarConfig"
    (logoutClicked)="onLogout()"
  >
  </apx-navigation-bar>
</ng-template>
<div
  id="content"
  [class.collapsed]="collapsed"
  [class.logged-in]="currentUser"
  [class.logged-out]="!currentUser"
>
  <div>
    <apx-loading-spinner>
      <router-outlet></router-outlet>
    </apx-loading-spinner>
  </div>
</div>
