import { Injectable } from '@angular/core';
import { getConfig } from 'shared/app';

@Injectable()
export class PhotoService {
  public get basePath() {
    return `${getConfig('apiUrl')}/web/api/photos`;
  }

  getUrl(id: string) {
    return `${this.basePath}/${id}`;
  }
}
