import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InlineSVGModule } from 'ng-inline-svg';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import { ModalContainerComponent } from './app/components/modal-container/modal-container.component';
import { SafeHtmlPipe } from './app/pipes/safe-html.pipe';
import { ResolveLinksPipe } from './app/pipes/resolve-links.pipe';
import { TooltipDirective } from 'shared/app/components/tooltips/tooltip.directive';
import { ReferenceModalComponent } from 'shared/app/components/tooltips/reference-modal/reference-modal.component';
import { SettingsModalComponent } from './app/components/settings-modal/settings-modal.component';
import { TaxonomyModalComponent } from 'shared/app/components/tooltips/taxonomy-modal/taxonomy-modal.component';
import { ResolveTermPipe } from './app/pipes/resolve-term.pipe';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationComponent } from './app/components/notification/notification/notification.component';

@NgModule({
  declarations: [
    ModalContainerComponent,
    SafeHtmlPipe,
    ResolveLinksPipe,
    ResolveTermPipe,
    TooltipDirective,
    ReferenceModalComponent,
    SettingsModalComponent,
    TaxonomyModalComponent,
    NotificationComponent
  ],
  imports: [
    CommonModule,
    InlineSVGModule,
    MatDialogModule,
    MatExpansionModule,
    MatSelectModule,
    MatButtonModule,
    TranslateModule,
    MatSnackBarModule
  ],
  exports: [
    ModalContainerComponent,
    SafeHtmlPipe,
    ResolveLinksPipe,
    TooltipDirective,
    ResolveTermPipe
  ]
})
export class SharedModule {}
