import { Feedback } from '../../chatbot/feedback/feedback';
import { ChatbotMessage } from '../../chatbot/chatbot-message';
import {
  ChatbotResponse,
  MessageReferencedSpans,
  MessageReference
} from '../../chatbot/chatbot-response';

export enum ChatAction {
  SEND_USER_MESSAGE = '[Chat] Send User Message',
  STORE_CHATBOT_MESSAGES = '[Chat] Store ChatBot Message',
  STORE_CHAT_ID = '[Chat] Store Chat Id',
  SET_USER_INPUT_DISABLED = '[Chat] Set User Input Disabled',
  SET_SHOW_JUMPING_DOTS = '[Chat] Set Show Jumping Dots',
  SET_CHAT_LANGUAGE = '[Chat] Set Chat Language',
  SET_CHAT_TOPIC = '[Chat] Set Chat Topic',
  STORE_CHATBOT_REFERENCES = '[Chat] Store ChatBot References',
  UPDATE_CHATBOT_MESSAGES = '[Chat] Update ChatBot Messages',
  SET_AVAILABLE_LANGUES = '[Chat] Set available Languages',
  SET_AVAILABLE_CONTEXT = '[Chat] Set available Context',
  CHANGE_CHAT_SESSION = '[Chat] Change Chat Session',
  GET_CHAT_LOG = '[Chat] Get Chat Log',
  STORE_FEEDBACK_ITEM = '[Chat] Store Feedback Item',
  DELETE_CONVERSATION = '[Chat] Delete Conversation',
  EDIT_CONVERSATION = '[Chat] Edit Conversation',
  SET_SWITCHING_CHAT_AVAILABLE = '[Chat] Set Switching Chat Available',
  SET_SELECTED_CONTEXT = '[Chat] Set selected Context',
  SET_SELECTED_MODEL = '[Chat] Set selected Model',
  SET_REFERENCES_FEEDBACK = '[Chat] Set References Feedback',
  UPDATE_REFERENCES_CONTEXT = '[Chat] Update References Context',
  IS_MOBILE_VERSION = '[Chat] Is Mobile Version'
}

export class SendUserMessage {
  public static readonly type = ChatAction.SEND_USER_MESSAGE;

  constructor(
    public payload: {
      messageWrapper: ChatbotMessage;
      invokedBySendMessage?: boolean;
    }
  ) {}
}

export class StoreChatId {
  public static readonly type = ChatAction.STORE_CHAT_ID;

  constructor(public payload: string) {}
}

export class StoreChatBotMessage {
  public static readonly type = ChatAction.STORE_CHATBOT_MESSAGES;

  constructor(public payload: ChatbotResponse) {}
}

export class UpdateChatBotMessage {
  public static readonly type = ChatAction.UPDATE_CHATBOT_MESSAGES;

  constructor(public payload: ChatbotResponse) {}
}

export class SetUserInputDisabled {
  public static readonly type = ChatAction.SET_USER_INPUT_DISABLED;

  constructor(public payload: boolean) {}
}

export class SetShowJumpingDots {
  public static readonly type = ChatAction.SET_SHOW_JUMPING_DOTS;

  constructor(public payload: boolean) {}
}

export class SetChatLanguage {
  public static readonly type = ChatAction.SET_CHAT_LANGUAGE;

  constructor(public payload: string) {}
}

export class SetChatTopic {
  public static readonly type = ChatAction.SET_CHAT_TOPIC;

  constructor(public payload: string) {}
}
export class StoreChatBotReferences {
  public static readonly type = ChatAction.STORE_CHATBOT_REFERENCES;

  constructor(public payload: MessageReference) {}
}
export class SetAvailableLanguages {
  public static readonly type = ChatAction.SET_AVAILABLE_LANGUES;

  constructor(public payload: { language: string; viewLanguage: string }[]) {}
}

export class SetAvailableContext {
  public static readonly type = ChatAction.SET_AVAILABLE_CONTEXT;

  constructor(public payload: string[]) {}
}

export class ChangeChatSession {
  public static readonly type = ChatAction.CHANGE_CHAT_SESSION;

  constructor(public payload: { chatId: string }) {}
}

export class GetChatLog {
  public static readonly type = ChatAction.GET_CHAT_LOG;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
}

export class StoreFeedbackItem {
  public static readonly type = ChatAction.STORE_FEEDBACK_ITEM;

  constructor(public payload: { messageId: string; feedback: Feedback }) {}
}

export class DeleteConversation {
  public static readonly type = ChatAction.DELETE_CONVERSATION;

  constructor(public payload: string) {}
}

export class EditConversation {
  public static readonly type = ChatAction.EDIT_CONVERSATION;

  constructor(public payload: { id: string; name: string }) {}
}
export class SetSwitchingChatAvailable {
  public static readonly type = ChatAction.SET_SWITCHING_CHAT_AVAILABLE;

  constructor(public payload: boolean) {}
}

export class SetSelectedContext {
  public static readonly type = ChatAction.SET_SELECTED_CONTEXT;

  constructor(public payload: string) {}
}

export class SetSelectedModel {
  public static readonly type = ChatAction.SET_SELECTED_MODEL;

  constructor(public payload: string) {}
}

export class StoreFeedbackReference {
  public static readonly type = ChatAction.SET_REFERENCES_FEEDBACK;

  constructor(public payload: { feedback: Feedback; messageId: string; documentid: string }) {}
}

export class UpdateReferencesContext {
  public static readonly type = ChatAction.UPDATE_REFERENCES_CONTEXT;

  constructor(public payload: { context: MessageReferencedSpans[]; referenceId: string }) {}
}

export class IsMobileVersion {
  public static readonly type = ChatAction.IS_MOBILE_VERSION;

  constructor(public payload: boolean) {}
}
