import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { getConfig } from 'shared/app';
import { Feedback } from './feedback';
import { NotificationService } from 'shared/app/components/notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  constructor(private httpClient: HttpClient, private notificationsService: NotificationService) {}

  sendFeedback(chatId: string, messageId: string, feedback: Feedback): Observable<Feedback> {
    return this.httpClient
      .put<Feedback>(`${this.apiUrl}/feedback/${chatId}/${messageId}`, feedback)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.openNotification(error.message, true);
          return of(feedback);
        })
      );
  }

  sendFeedbackReference(chatId: string, messageId: string, documentid: string, feedback: Feedback) {
    return this.httpClient
      .put<Feedback>(`${this.apiUrl}/feedback/${chatId}/${messageId}/${documentid}`, feedback)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.openNotification(error.message, true);
          return of(feedback);
        })
      );
  }

  private get apiUrl(): string {
    return getConfig('apiUrl');
  }
}
