<div class="notification">
  <div class="content">
    <span class="material-icons icon" [ngClass]="{ success: !data.error, error: data.error }">
      check_circle
    </span>
    <div class="message-wrapper">
      <span class="status" [ngClass]="{ success: !data.error, error: data.error }">{{
        status
      }}</span>
      <span class="message">{{ data.message }}</span>
    </div>
  </div>
  <span class="material-icons close" (click)="close()"> close </span>
</div>
