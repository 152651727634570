import { AfterViewInit, Injectable, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class TokenService implements AfterViewInit {
  private token: string;
  constructor(protected keycloakAngular: KeycloakService) {}

  ngAfterViewInit() {
    this.keycloakAngular.getToken().then(token => {
      this.token = token;
    });
  }

  public getToken(): string {
    return this.token;
  }
}
