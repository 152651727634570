import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import {
  Application,
  MenuItem,
  NavigationBarConfig,
  NavigationBarService,
  User as NavigationBarUser
} from '@apiaxt/web-commons';

import { AuthorizationService } from 'shared/core';
import { getConfig } from 'shared/app';

declare let gtag;

@UntilDestroy()
@Component({
  selector: 'app-root',
  styleUrls: ['app.component.scss'],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  public currentUser: NavigationBarUser;
  public navigationBarConfig: NavigationBarConfig;
  public collapsed: boolean;

  private selectedMenuItem: MenuItem;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private authorizationService: AuthorizationService,
    private navBarService: NavigationBarService
  ) {
    this.getUser();

    this.navigationBarConfig = {
      monitoringUrl: getConfig('monitoringWebUrl'),
      platformUrl: getConfig('rulesWebUrl'),
      reportingUrl: getConfig('reportingWebUrl'),
      taxonomyUrl: getConfig('taxonomyWebUrl'),
      companyManagerUrl: getConfig('adminWebUrl'),
      knowledgeBaseUrl: getConfig('knowledgebaseWebUrl'),
      appsUrl: getConfig('appsWebUrl'),
      userPhotoBaseUrl: getConfig('userPhotoBaseUrl'),
      instrumentsCheckUrl: getConfig('instrumentsCheckUrl'),
      instrumentManagerUrl: getConfig('instrumentManagerWebUrl'),
      clientProfileManagerUrl: getConfig('clientProfileManagerWebUrl'),
      chatbotUrl: '',
      currentApplication: Application.Chatbot
    };

    // Google Analytics
    if (getConfig('analyticsCode')) {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=' + getConfig('analyticsCode');
      document.head.prepend(script);

      const navEndEvent$ = router.events.pipe(filter(e => e instanceof NavigationEnd));
      navEndEvent$.subscribe((e: NavigationEnd) => {
        gtag('config', getConfig('analyticsCode'), { page_path: e.urlAfterRedirects });
      });
    }
  }

  public onLogout() {
    this.authorizationService.logout();
  }

  ngOnInit() {
    console.log('Demo Chatbot is starting...');
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.cdr.markForCheck();
      });

    this.navBarService
      .getSelectedMenuItem()
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(selectedMenuItem => {
        if (selectedMenuItem) {
          this.selectedMenuItem = selectedMenuItem;
          this.cdr.markForCheck();
        }
      });

    this.navBarService
      .getCollapsible()
      .pipe(untilDestroyed(this))
      .subscribe(collapsed => {
        this.collapsed = collapsed;
      });
  }

  private getUser() {
    this.authorizationService.currentUser.pipe(untilDestroyed(this)).subscribe(user => {
      if (user) {
        this.currentUser = {
          organizationName: user.organization?.name || '',
          firstName: user.firstName,
          lastName: user.lastName,
          organizationId: user.organizationId,
          photoId: user.photoId,
          permissions: user.activities
        };
      }
    });
  }
}
