import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, NgZone } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule } from '@angular/router';

import {
  NavigationBarService,
  NotificationComponent,
  NotificationModule,
  WebCommonsModule
} from '@apiaxt/web-commons';
import { AgentConfigOptions } from '@elastic/apm-rum';
import { ApmErrorHandler, ApmModule, ApmService } from '@elastic/apm-rum-angular';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { LocalStorageService, NgxWebstorageModule } from 'ngx-webstorage';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { getConfig } from 'shared/app';
import { ActivityAllowedGuard, AuthGuard } from 'shared/app/guards';
import { LoadingGuard } from 'shared/app/guards/loading.guard';
import { CoreModule } from 'shared/core/core.module';
import version from '../version';
import { DataResolver } from './app.resolver';
import { ROUTES } from './app.routes';
import { SharedModule } from 'shared/shared.module';
import { ChatState } from './state/chat/chat.state';

const GUARDS_PROVIDERS = [AuthGuard, LoadingGuard, ActivityAllowedGuard];

const APP_RESOLVER_PROVIDERS = [DataResolver];
const MY_APP_PROVIDERS: any[] = [...GUARDS_PROVIDERS];

// Application wide providers
const APP_PROVIDERS = [...APP_RESOLVER_PROVIDERS, ...MY_APP_PROVIDERS];

const MY_APP_IMPORTS: any[] = [
  NgxWebstorageModule.forRoot({
    prefix: 'apx',
    separator: '.'
  })

  // app shared modules
];

export const ADVANCE_MODULES = [
  CoreModule.forRoot([]),
  RouterModule.forRoot(ROUTES, {
    useHash: getConfig('useHash'),
    preloadingStrategy: PreloadAllModules,
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
  }),
  NotificationModule,
  ToastrModule.forRoot({
    toastComponent: NotificationComponent,
    timeOut: 0,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-right',
    tapToDismiss: false,
    toastClass: 'notificationClass'
  }),
  ToastContainerModule
];

export function kcInitializer(
  keycloak: KeycloakService,
  ngZone: NgZone,
  localStorage: LocalStorageService
): () => Promise<any> {
  return (): Promise<any> => {
    localStorage.clear('kc-logout');
    localStorage.observe('kc-logout').subscribe(() => keycloak.logout());
    return ngZone.runOutsideAngular(() => keycloak.init(getConfig('keycloakOptions')));
  };
}

export function HttpLoaderFactory(http: HttpClient) {
  const rightNow = new Date();
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cacheBuster=' + rightNow.getTime());
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    ApmModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSidenavModule,
    ...ADVANCE_MODULES,
    ...MY_APP_IMPORTS,
    KeycloakAngularModule,
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsModule.forRoot([ChatState]),
    MatIconModule,
    WebCommonsModule,
    SharedModule,
    MatInputModule,
    MatSelectModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    // expose our Services and Providers into Angular's dependency injection
    { provide: ErrorHandler, useClass: ApmErrorHandler },
    ApmService,
    {
      provide: APP_INITIALIZER,
      useFactory: kcInitializer,
      multi: true,
      deps: [KeycloakService, NgZone, LocalStorageService]
    },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    NavigationBarService,
    ...APP_PROVIDERS
  ]
})
export class AppModule {
  constructor(apmService: ApmService) {
    const apmConfig = getConfig('apm');
    if (apmConfig?.active) {
      const options: AgentConfigOptions = {
        serviceVersion: version,
        active: false,
        environment: 'local',
        ...apmConfig
      };
      apmService.init(options);
    }
  }
}
