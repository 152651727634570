import { CdkNoDataRow } from '@angular/cdk/table';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

import { Activity, AuthorizationService } from 'shared/core';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected router: Router,
    protected keycloakAngular: KeycloakService,
    protected authorizationService: AuthorizationService
  ) {
    super(router, keycloakAngular);
  }

  isAccessAllowed(): Promise<boolean> {
    return new Promise(resolve => {
      if (!this.authenticated) {
        this.authorizationService.currentUser.next(null);
        this.keycloakAngular
          .login()
          .then(() => resolve(true))
          .catch(() => resolve(false));
      }

      if (!this.authorizationService.currentUser.getValue()) {
        this.authorizationService.currentUser.next({
          activities: this.keycloakAngular.getKeycloakInstance().tokenParsed['activities'],
          email: this.keycloakAngular.getKeycloakInstance().tokenParsed['email'],
          organizationId: this.keycloakAngular.getKeycloakInstance().tokenParsed['oid'],
          photoId: this.keycloakAngular.getKeycloakInstance().tokenParsed['photoId'],
          id: this.keycloakAngular.getKeycloakInstance().tokenParsed['uid'],
          firstName: this.keycloakAngular.getKeycloakInstance().tokenParsed['firstName'],
          lastName: this.keycloakAngular.getKeycloakInstance().tokenParsed['lastName'],
          organization: {
            id: this.keycloakAngular.getKeycloakInstance().tokenParsed['oid'],
            name: this.keycloakAngular.getKeycloakInstance().tokenParsed['oname']
          }
        });
      }

      const activities = this.authorizationService.currentUser.getValue().activities;
      return resolve(activities.includes(Activity.DocumentsChatbot));
    });
  }
}
