import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { Observable } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';

import { getConfig } from 'shared/app';
import { ChatbotResponse, ChatLogSession, ChatSession } from './chatbot-response';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class ChatbotService {
  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService,
    private translateService: TranslateService
  ) {}

  startConversation(
    language: string,
    context: string,
    model: string,
    topic?: string
  ): Observable<ChatbotResponse> {
    const apiUrl = this.apiUrl();
    const data = { language, topic, context, model };
    return this.httpClient.post<ChatbotResponse>(apiUrl + 'start', data).pipe(
      timeout(90_000),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return this.translateError(error);
      })
    );
  }

  sendMessage(message: string, chatId: string | null): Observable<ChatbotResponse> {
    const apiUrl = this.apiUrl();
    const data = { message };
    if (chatId != null) {
      data['chatId'] = chatId;
    }
    return this.httpClient.post<ChatbotResponse>(apiUrl, data).pipe(
      timeout(120_000),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return this.translateError(error);
      })
    );
  }

  sendRulesMessage(message: string, chatId: string | null): Observable<ChatbotResponse> {
    const apiUrl = this.apiUrl();
    const data = { message };
    if (chatId != null) {
      data['chatId'] = chatId;
    }
    return this.httpClient.post<ChatbotResponse>(apiUrl + 'rules', data).pipe(
      timeout(90_000),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return this.translateError(error);
      })
    );
  }

  sendCountryManualsMessage(message: string, chatId: string | null): Observable<ChatbotResponse> {
    const apiUrl = this.apiUrl();
    const data = { message };
    if (chatId != null) {
      data['chatId'] = chatId;
    }
    return this.httpClient.post<ChatbotResponse>(apiUrl + 'country-manuals', data).pipe(
      timeout(90_000),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return this.translateError(error);
      })
    );
  }

  getChatLog(): Observable<ChatLogSession[]> {
    const apiUrl = this.apiUrl(false);
    return this.httpClient.get<ChatLogSession[]>(apiUrl + 'history/').pipe(
      timeout(90_000),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return this.translateGetConversationLogError(error);
      })
    );
  }

  changeChat(id: string): Observable<ChatSession> {
    const apiUrl = this.apiUrl(false);
    return this.httpClient.get<ChatSession>(apiUrl + 'history/' + id).pipe(
      timeout(90_000),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return this.translateConverstionEditError(error);
      })
    );
  }

  deleteChat(id: string): Observable<{ deleted: boolean; error: string }> {
    const apiUrl = this.apiUrl(false);
    return this.httpClient.delete<boolean>(apiUrl + 'history/' + id).pipe(
      map(response => {
        return {
          deleted: response,
          error: ''
        };
      }),
      timeout(90_000),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return this.translateConversationDeleteError(error);
      })
    );
  }

  editChat(id: string, name: string): Observable<ChatSession> {
    const apiUrl = this.apiUrl(false);
    return this.httpClient.patch<ChatSession>(apiUrl + 'history/' + id, { title: name }).pipe(
      timeout(90_000),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return this.translateConverstionEditError(error);
      })
    );
  }

  private apiUrl(withChatPrefix = true): string {
    return withChatPrefix ? `${getConfig('apiUrl')}/chat/` : `${getConfig('apiUrl')}/`;
  }

  private translateError(error: HttpErrorResponse): Observable<ChatbotResponse> {
    return this.translateService
      .get((error.error && error.error.message) || 'ERROR-MESSAGE.GENERAL')
      .pipe(
        map(translatedMessage => {
          return {
            chatId: null,
            type: 'error',
            messageId: '',
            message: translatedMessage,
            context: []
          };
        })
      );
  }

  private translateGetConversationLogError(error: HttpErrorResponse): Observable<ChatLogSession[]> {
    return this.translateService
      .get((error.error && error.error.message) || 'ERROR-MESSAGE.GENERAL')
      .pipe(
        map(translatedMessage => {
          return [
            {
              id: '',
              started_at: new Date(),
              title: translatedMessage
            }
          ];
        })
      );
  }

  private translateConverstionEditError(error: HttpErrorResponse): Observable<ChatSession> {
    return this.translateService
      .get((error.error && error.error.message) || 'ERROR-MESSAGE.GENERAL')
      .pipe(
        map(translatedMessage => {
          return {
            id: '',
            started_at: new Date(),
            user_id: '',
            user_email: '',
            language: '',
            topic: '',
            title: translatedMessage,
            context: '',
            deleted: false,
            chat_history: [],
            chatbot_version: '',
            legacy_chat_session_id: ''
          };
        })
      );
  }

  private translateConversationDeleteError(error: HttpErrorResponse): Observable<{
    deleted: boolean;
    error: string;
  }> {
    return this.translateService
      .get((error.error && error.error.message) || 'ERROR-MESSAGE.GENERAL')
      .pipe(
        map(translatedMessage => {
          return {
            deleted: false,
            error: translatedMessage
          };
        })
      );
  }
}
